import { Component, OnInit,Inject } from '@angular/core'; 

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { UsercreateComponent } from '../usercreate/usercreate.component';
import { TodoService } from 'src/app/services/todo.service';





@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

data={}

  constructor(
    public dialog: MatDialog,
    private todoService:TodoService
  ) {}

  openDialog(): void {
    this.dialog.open(UsercreateComponent)
  }
  /*
  addActive(name,surname,imgurl):void
  {
    //alert(name.value);
    // console.log(name.value+surname.value+imgurl.value);
    this.data.active.push(name.value+' '+surname.value);
    name.value='';
    surname.value='';
    imgurl.value='';
  }
*/


addUser(email,name,surname,avatar)
{
  const obj ={email:email.value,first_name:name.value,last_name:surname.value,avatar:avatar.value};
  this.todoService.addUser(obj)
  .subscribe((res)=>{
    console.log(res);
    this.data['active'].push(res);
  },(err)=>{
    console.log(err);
  })
}

deleteUser(id,key)
{
  const obj ={id:id};
  this.todoService.deleteUser(obj)
  .subscribe((res)=>{
    console.log(key);
    alert("Silme işlemi başarılı");
    // this.data['active']['id'][id].pop();
  },(err)=>{
    console.log(err);
  })
}

listUser()
{
  this.todoService.listUser()
  .subscribe((res)=>{
    //console.log(res);
    this.data['active']=res['data'];
    this.data['per_page']=res['per_page'];
    this.data['page']=res['page'];
    console.log(this.data['active']);
  },(err)=>{
    console.log(err);
  })
}


  ngOnInit() {
    this.listUser();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
               
                  
    }
  }



  

}
