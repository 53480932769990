import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  
  constructor(
    @Inject('apiUrl') private apiUrl,
    private http:HttpClient) { }

 

  addUser(obj)
  {
    return this.http.post(this.apiUrl+'/users',obj);
  }
  listUser()
  {
    return this.http.get(this.apiUrl+'/users');
  }

  listUserUpdate(obj)
  {
    return this.http.get(this.apiUrl+'/users/'+obj);
  }

  updateUser(obj)
  {
    return this.http.put(this.apiUrl+'/users',obj);
  }

  deleteUser(id)
  {
    return this.http.delete(this.apiUrl+'/users/'+id);
  }
}
