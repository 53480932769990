import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatButtonModule,MatCheckboxModule,MatFormFieldModule,MatDialogModule} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { AboutComponent } from './component/about/about.component';
import { NotFountComponent } from './component/not-fount/not-fount.component';
import { UsercreateComponent } from './component/usercreate/usercreate.component';
import { UserupdateComponent } from './component/userupdate/userupdate.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    NotFountComponent,
    UsercreateComponent,
    UserupdateComponent
  ],
  entryComponents:[UsercreateComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    DragDropModule,
    AppRoutingModule,
    MatDialogModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    {provide:'apiUrl',useValue:'https://reqres.in/api'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
