import { Component, OnInit } from '@angular/core';
import { TodoService } from 'src/app/services/todo.service';

@Component({
  selector: 'app-userupdate',
  templateUrl: './userupdate.component.html',
  styleUrls: ['./userupdate.component.scss']
})
export class UserupdateComponent implements OnInit {

  data={}

  constructor(
    private todoService:TodoService
  ) {}




  updateUser(email,name,surname,avatar)
  {
    var myParam = location.search.split('id=')[1];
    const obj ={id:myParam,email:email.value,first_name:name.value,last_name:surname.value,avatar:avatar.value};
    this.todoService.updateUser(obj)
    .subscribe((res)=>{
      console.log(res);
      // this.data['active'].push(res);
      this.data['avatar']=avatar.value;
      this.data['email']=email.value;
      this.data['first_name']=name.value;
      this.data['last_name']=surname.value;
      alert('Güncelleme Başarılı');
    },(err)=>{
      console.log(err);
    })
  }

  listUserUpdate()
  {
    var myParam = location.search.split('id=')[1];
    this.todoService.listUserUpdate(myParam)
    .subscribe((res)=>{
      //console.log(res);
      this.data['avatar']=res['data']['avatar'];
      this.data['email']=res['data']['email'];
      this.data['first_name']=res['data']['first_name'];
      this.data['last_name']=res['data']['last_name'];
      this.data['id']=res['data']['id'];
      console.log(this.data['active']);
    },(err)=>{
      console.log(err);
    })
  }


  ngOnInit() {
    this.listUserUpdate();
  }

}
