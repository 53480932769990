import { Component, OnInit, Inject} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HomeComponent } from '../home/home.component';
import { TodoService } from 'src/app/services/todo.service';


@Component({
  selector: 'app-usercreate',
  templateUrl: './usercreate.component.html',
  styleUrls: ['./usercreate.component.scss']
})
export class UsercreateComponent implements OnInit {
  data={};
 

  listUser()
{
  this.todoService.listUser()
  .subscribe((res)=>{
    //console.log(res);
    this.data['active']=res['data'];
    console.log(this.data['active']);
  },(err)=>{
    console.log(err);
  })
}


  ngOnInit() {
    this.listUser();
  }
  constructor(
    private todoService:TodoService,
    public dialogRef: MatDialogRef<UsercreateComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  addUser(email,name,surname,avatar)
  {
    const obj ={email:email.value,first_name:name.value,last_name:surname.value,avatar:avatar.value};
    this.todoService.addUser(obj)
    .subscribe((res)=>{
      console.log(res);
      this.data['active'].push(res);
      alert('Ekleme Başarılı');
    },(err)=>{
      console.log(err);
    })
  }

}
